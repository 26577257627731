import React, {useEffect} from 'react';
import styled from 'styled-components';
import { UsersIcon, BookmarkIcon, WrenchIcon, AcademicCapIcon, CommandLineIcon, CodeBracketIcon } from '@heroicons/react/24/outline'
import WelcomeCover from "../partials/WelcomeCover";
import SmallBadge from "../partials/SmallBadge";
const Home = () => {
    useEffect(() => {
        document.title = "Welcome - mlsvd.de";
    }, []);
    const features = [
        {
            name: 'Development',
            description:
                <div>
                    <table className="table-auto w-full">
                        <tr className="text-sm leading-normal">
                            <td className="py-3 px-6 text-left">Languages</td>
                            <td className="py-3 px-6 text-left">
                                <SmallBadge badgeText="PHP"/>
                                <SmallBadge badgeText="Go"/>
                            </td>
                        </tr>
                        <tr className="text-sm leading-normal">
                            <td className="py-3 px-6 text-left">Frameworks & libs</td>
                            <td className="py-3 px-6 text-left">
                                <SmallBadge badgeText="Symfony"/>
                                <SmallBadge badgeText="API Platform"/>
                                <SmallBadge badgeText="Doctrine ORM"/>
                                <SmallBadge badgeText="GORM (for Go)"/>
                                <SmallBadge badgeText="GIN (for Go)"/>
                            </td>
                        </tr>
                    </table>
                </div>,
            icon: CodeBracketIcon,
        },
        {
            name: 'Principles, Concepts',
            description:
                <div>
                    <table className="table-auto w-full">
                        <tr className="text-sm leading-normal">
                            <td className="py-3 px-6 text-left">Standards</td>
                            <td className="py-3 px-6 text-left">
                                <SmallBadge badgeText="Patterns"/>
                                <SmallBadge badgeText="SOLID"/>
                                <SmallBadge badgeText="DDD"/>
                                <SmallBadge badgeText="ACID (DB)"/>
                            </td>
                        </tr>
                        <tr className="text-sm leading-normal">
                            <td className="py-3 px-6 text-left">Architecture</td>
                            <td className="py-3 px-6 text-left">
                                <SmallBadge badgeText="APIs"/>
                                <SmallBadge badgeText="Distributed systems"/>
                                <SmallBadge badgeText="Event driven system"/>
                                <SmallBadge badgeText="B2B/B2C"/>
                            </td>
                        </tr>
                    </table>
                </div>,
            icon: AcademicCapIcon,
        },
        {
            name: 'Stack, Tools',
            description:
                <div>
                    <table className="table-auto w-full">
                        <tr className="text-sm leading-normal">
                            <td className="py-3 px-6 text-left">Databases</td>
                            <td className="py-3 px-6 text-left">
                                <SmallBadge badgeText="MySQL"/>
                                <SmallBadge badgeText="PostgreSQL"/>
                            </td>
                        </tr>
                        <tr className="text-sm leading-normal">
                            <td className="py-3 px-6 text-left">NoSQL</td>
                            <td className="py-3 px-6 text-left">
                                <SmallBadge badgeText="MongoDB"/>
                                <SmallBadge badgeText="Redis"/>
                                <SmallBadge badgeText="Memcache"/>
                            </td>
                        </tr>
                        <tr className="text-sm leading-normal">
                            <td className="py-3 px-6 text-left">Search engines</td>
                            <td className="py-3 px-6 text-left">
                                <SmallBadge badgeText="Elasticsearch"/>
                            </td>
                        </tr>
                        <tr className="text-sm leading-normal">
                            <td className="py-3 px-6 text-left">Other</td>
                            <td className="py-3 px-6 text-left">
                                <SmallBadge badgeText="Docker"/>
                                <SmallBadge badgeText="git"/>
                                <SmallBadge badgeText="Linux/*nix"/>
                            </td>
                        </tr>
                    </table>
                </div>,
            icon: WrenchIcon,
        },
        {
            name: 'Practices, Approaches',
            description:
                <div>
                    <table className="table-auto w-full">
                        <tr className="text-sm leading-normal">
                            <td className="py-3 px-6 text-left">CI/CD</td>
                            <td className="py-3 px-6 text-left">
                                <SmallBadge badgeText="Gitlab CI/CD"/>
                                <SmallBadge badgeText="Github CI"/>
                                <SmallBadge badgeText="Bamboo CI/CD"/>
                            </td>
                        </tr>
                        <tr className="text-sm leading-normal">
                            <td className="py-3 px-6 text-left">Best Practices</td>
                            <td className="py-3 px-6 text-left">
                                <SmallBadge badgeText="Testing (TDD/Classical pyramid)"/>
                                <SmallBadge badgeText="OWASP"/>
                                <SmallBadge badgeText="Sonar/Blackduck"/>
                            </td>
                        </tr>
                    </table>
                </div>,
            icon: BookmarkIcon,
        },
        {
            name: 'Soft skills',
            description:
                <div>
                    <table className="table-auto w-full">
                        <tr className="text-sm leading-normal">
                            <td className="py-3 px-6 text-left"></td>
                            <td className="py-3 px-6 text-left">
                                <SmallBadge badgeText="Communication"/>
                                <SmallBadge badgeText="Problem-solving"/>
                                <SmallBadge badgeText="Teamwork"/>
                                <SmallBadge badgeText="Critical Thinking"/>
                            </td>
                        </tr>
                    </table>
                </div>,
            icon: UsersIcon,
        },
        {
            name: 'Other',
            description:
                <div>
                    <table className="table-auto w-full">
                        <tr className="text-sm leading-normal">
                            <td className="py-3 px-6 text-left">Hobby langs</td>
                            <td className="py-3 px-6 text-left">
                                <SmallBadge badgeText="Python"/>
                                <SmallBadge badgeText="JS"/>
                                <SmallBadge badgeText="C#"/>
                                <SmallBadge badgeText="Android"/>
                            </td>
                        </tr>
                        <tr className="text-sm leading-normal">
                            <td className="py-3 px-6 text-left">Hobby skillset</td>
                            <td className="py-3 px-6 text-left">
                                <SmallBadge badgeText="node/react/vue"/>
                                <SmallBadge badgeText=".net"/>
                                <SmallBadge badgeText="Desktop programming"/>
                                <SmallBadge badgeText="ML(tensor)"/>
                            </td>
                        </tr>
                        <tr className="text-sm leading-normal">
                            <td className="py-3 px-6 text-left">Interests</td>
                            <td className="py-3 px-6 text-left">
                                <SmallBadge badgeText="AI"/>
                                <SmallBadge badgeText="High load systems"/>
                                <SmallBadge badgeText="optimisation"/>
                                <SmallBadge badgeText="computer vision"/>
                            </td>
                        </tr>
                    </table>
                </div>,
            icon: CommandLineIcon,
        },
    ]

    return (
        <HomeContainer className="homepage-container background-main-dark text-white py-24 sm:py-32">
            <WelcomeCover/>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">

                <div className="mx-auto max-w-2xl lg:text-center">
                    <p className="mt-2 text-2xl font-bold tracking-tight text-white-900 sm:text-4xl">
                        Greetings, honored visitor!
                    </p>
                    <p className="mt-6 text-2xl leading-8 text-white-600">
                        I'm Dmitri Malisev, an experienced software developer sharing my journey through the digital landscape.
                    </p>
                    <p className="mt-6 text-xl leading-8 text-white-600">
                        Here, I showcase my profile, projects, articles, and more. If you're curious about tech topics,
                        looking to team up on a new venture, or just want to unwind with some tech humor, feel free to
                        drop me a line!
                    </p>
                    <p className="mt-6 text-xl leading-8 text-white-600">Enjoy your stay!</p>
                </div>
                <p className="mt-20 text-2xl font-bold tracking-tight text-white-900 sm:text-2xl">
                    Here is a concise overview of my skills and capabilities
                </p>
                <div className="mx-auto mt-5 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                        {features.map((feature) => (
                            <div key={feature.name} className="relative pl-16 bg-slate-800 px-2 py-2 rounded-lg">
                                <dt className="text-base font-semibold leading-7 text-white-900">
                                    <div
                                        className="absolute left-2 top-2 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true"/>
                                    </div>
                                    {feature.name}
                                </dt>
                                <dd className="mt-2 text-base leading-7 text-white-600">{feature.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
                {/*<p className="mt-20 text-2xl font-bold tracking-tight text-white-900 sm:text-2xl">*/}
                {/*    Find out more details about my skills and experience visiting <AboutLink to="/about">"About"</AboutLink> page*/}
                {/*</p>*/}
            </div>
        </HomeContainer>
    );
};

const HomeContainer = styled.div`
    padding: 2rem;
    text-align: center;
`;

export default Home;
